import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div>
      <ul style={{listStyle: `none`,}}>
      <li>
          <Link to="/basics">The Basics</Link>
        </li>
        <li>
          <Link to="/keybinds">Keybinds</Link>
        </li>
        <li>
          <Link to="/race">What race should I choose?</Link>
        </li>
        <li>
          <Link to="/class">What class should I play?</Link>
        </li>
        <li>
          <Link to="/addons">Addons</Link>
        </li>
        <li>
          <Link to="/macros">Macros</Link>
        </li>
        <li>
          <Link to="/aboutme">About Me</Link>
        </li>
      </ul>
      <h6>Advanced Guides (Coming Soon...)</h6>
      <ul style={{listStyle: `none`,}}>
        <li>
          <Link >Timewalking</Link>
        </li>
        <li>
          <Link >Pet Battling</Link>
        </li>
        <li>
          <Link >Indepth: Restoration Shaman</Link>
        </li>
      </ul>
    </div>
  </Layout>
)

export default IndexPage
